import * as React from "react";

const LightIcon = ({
  width = 18,
  height = 28,
  fill = "#111928",
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 18 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.795 19.765a.49.49 0 0 1-.484-.478c-.013-.33-.018-.66-.009-.99.026-.877-.277-1.613-.952-2.318C1.749 14.31.755 12.611.312 10.79-.232 8.554.206 6.102 1.515 4.06 2.87 1.948 4.987.513 7.322.121c2.544-.423 5.114.27 7.057 1.904 1.939 1.63 3.105 4.076 3.2 6.71.056 1.583-.37 3.114-1.265 4.549a20.43 20.43 0 0 1-2.025 2.707c-.658.744-.956 1.491-.94 2.353.005.254.002.508 0 .763-.004.273-.222.527-.487.527h-.005c-.268-.003-.483-.193-.48-.468.004-.315.008-.558.003-.802-.022-1.117.368-2.112 1.191-3.043a19.461 19.461 0 0 0 1.927-2.575c.787-1.26 1.16-2.598 1.112-3.974-.086-2.384-1.096-4.506-2.845-5.977C12.01 1.32 9.78.72 7.478 1.105 5.409 1.45 3.53 2.728 2.325 4.609c-1.16 1.81-1.551 3.974-1.073 5.938.4 1.646 1.313 3.194 2.789 4.735.86.898 1.264 1.894 1.23 3.045-.008.306-.004.613.009.92a.493.493 0 0 1-.465.517h-.02ZM12.789 22.368H4.656a.491.491 0 0 1-.484-.497c0-.276.216-.498.484-.498h8.133c.268 0 .485.222.485.497a.491.491 0 0 1-.485.498ZM11.707 25.184H5.74a.491.491 0 0 1-.485-.498.49.49 0 0 1 .485-.497h5.968c.268 0 .485.222.485.497a.491.491 0 0 1-.485.498ZM10.99 28H6.456a.491.491 0 0 1-.485-.498c0-.275.217-.498.485-.498h4.532c.268 0 .485.223.485.498a.491.491 0 0 1-.485.498Z"
      fill={fill}
    />
  </svg>
);

export default LightIcon;
