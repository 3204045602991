import { Box } from "@chakra-ui/layout";
import * as React from "react";

const TempAreasIcon = ({
  width = 8,
  height = 21,
  color = "#111928",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 8 21">
    <path
      d="M4.279 20.598c-1.73 0-3.207-1.236-3.547-3.056-.291-1.561.412-3.144 1.75-3.939.007-2.204.007-4.386.007-6.567l-.006-4.269c0-1.02.802-1.85 1.788-1.85 1.028 0 1.83.83 1.83 1.85l-.006 3.494.007 7.334c.933.59 1.505 1.417 1.732 2.482.213 1 .009 2.06-.56 2.908a3.604 3.604 0 0 1-2.451 1.574 3.941 3.941 0 0 1-.544.039ZM3.067 2.766l.005 4.27c0 2.181 0 4.362.003 6.543 0 .251-.096.425-.303.547-1.122.668-1.713 1.993-1.468 3.3.316 1.688 1.794 2.776 3.437 2.534a3.018 3.018 0 0 0 2.056-1.32c.483-.721.65-1.586.469-2.435-.199-.929-.684-1.618-1.485-2.106-.062-.037-.255-.154-.26-.368L5.514 6.26l.005-3.494c0-.687-.54-1.246-1.204-1.246a1.237 1.237 0 0 0-1.248 1.246Z"
      fill={color}
    />
    <path
      d="M4.211 4.183h-1.19a.296.296 0 0 1-.29-.302c0-.167.13-.301.29-.301h1.19c.162 0 .291.135.291.301 0 .166-.13.302-.29.302ZM3.604 6.27H3.02a.296.296 0 0 1-.291-.302c0-.168.13-.302.291-.302h.585c.162 0 .291.135.291.302a.295.295 0 0 1-.29.301ZM4.21 8.356H3.02a.296.296 0 0 1-.29-.302c0-.167.13-.301.29-.301h1.19c.162 0 .292.135.292.301 0 .166-.131.302-.292.302ZM3.716 10.442H3.02a.296.296 0 0 1-.292-.301c0-.167.131-.302.292-.302h.695c.162 0 .291.135.291.302 0 .166-.13.301-.291.301ZM4.21 12.527H3.02a.296.296 0 0 1-.29-.301c0-.168.13-.302.29-.302h1.19c.162 0 .292.136.292.302 0 .167-.131.301-.292.301Z"
      fill={color}
    />
  </svg>
);

export default TempAreasIcon;
