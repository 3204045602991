
// New comfort score list & colorsv

export enum ComfortScore2 {
  Cold = "Cold",
  Dry = "Dry",
  DryAndCold = "Dry And Cold",
  DryAndHot = "Dry And Hot",
  Hot = "Hot",
  Muggy = "Muggy",
  MuggyAndCold = "Muggy And Cold",
  MuggyAndHot = "Muggy And Hot",
  PerfectComfort = "Perfect Comfort",
}

export enum ComfortScoreColor2 {
  "Cold" = "#04538C",
  "Dry" = "#F2A63D",
  "Dry And Cold" = "#11A9C8",
  "Dry And Hot" = "#EC622D",
  "Hot" = "#FB0C49",
  "Muggy" = "#7133C3",
  "Muggy And Cold" = "#1A6FD4",
  "Muggy And Hot" = "#F84098",
  "Perfect Comfort" = "#85AB19",
}

export enum InsightRisk {
  Low = "Low",
  Mid = "Mid",
  High = "High",
  None = "None"
}

export enum RiskColors {
  Low = "#6DC96E",
  Mid = "#F2A63D",
  High = "#FB5169",
  None = "#595F6A",
};