import * as React from "react";

const Co2AreasIcon = ({
  width = 24,
  height = 23,
  color = "#595F6A",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 23">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.503 4.368c-.311 0-.619.02-.918.062a5.53 5.53 0 0 0-1.807.571 5.843 5.843 0 0 0-2.094 1.837 4.745 4.745 0 0 0-.852 2.388 4.881 4.881 0 0 0 0 .844c.018.15-.063.283-.22.347-.482.214-.89.474-1.237.791-.899.815-1.413 1.793-1.529 2.909-.068.678.01 1.334.232 1.951.24.655.635 1.233 1.208 1.764.43.4.886.704 1.395.929.63.277 1.33.417 2.082.417H18.27c.153 0 .307-.001.46-.007.45-.019.88-.102 1.275-.249a4.206 4.206 0 0 0 2.096-1.68c.54-.832.701-1.774.48-2.798-.31-1.43-1.5-2.597-3.033-2.97l-.127-.031a.281.281 0 0 1-.197-.162.252.252 0 0 1 .026-.243c.37-.531.5-1.11.398-1.767-.094-.599-.412-1.109-.973-1.558-.84-.673-2.424-.815-3.443.047a.303.303 0 0 1-.25.064.283.283 0 0 1-.203-.151l-.147-.285c-.08-.153-.158-.306-.25-.452-.447-.711-1.082-1.309-1.886-1.776a5.825 5.825 0 0 0-2.993-.792Zm4.6 15.345h-8.34c-.837 0-1.62-.158-2.327-.47a5.63 5.63 0 0 1-1.554-1.032c-.628-.583-1.08-1.246-1.345-1.971a4.89 4.89 0 0 1-.26-2.174c.129-1.246.7-2.336 1.699-3.24a5.107 5.107 0 0 1 1.276-.839 5.4 5.4 0 0 1 .01-.803c.077-.972.395-1.86.944-2.639a6.393 6.393 0 0 1 2.292-2.01A6.132 6.132 0 0 1 8.5 3.903c.42-.058.854-.08 1.292-.063a6.42 6.42 0 0 1 3.008.868c.882.513 1.58 1.172 2.076 1.959.097.155.182.317.266.48 1.154-.75 2.83-.7 3.906.161.67.538 1.052 1.155 1.166 1.887a2.853 2.853 0 0 1-.293 1.824c1.635.484 2.885 1.765 3.22 3.32.251 1.16.066 2.23-.55 3.181a4.758 4.758 0 0 1-3.836 2.185c-.162.007-.323.008-.485.008h-4.168Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.408 15.594c-1.326 0-2.405-1.007-2.405-2.246 0-1.238 1.079-2.245 2.405-2.245.475 0 .934.13 1.328.373a.256.256 0 0 1 .08.37.3.3 0 0 1-.396.075 1.92 1.92 0 0 0-1.012-.284c-1.01 0-1.833.768-1.833 1.711 0 .944.822 1.711 1.833 1.711.368 0 .722-.1 1.025-.291a.299.299 0 0 1 .397.071.256.256 0 0 1-.076.371c-.398.25-.863.384-1.346.384ZM12.825 11.637c-1.01 0-1.833.768-1.833 1.711 0 .944.822 1.711 1.833 1.711.368 0 .722-.1 1.025-.292.506-.319.808-.85.808-1.419 0-.575-.307-1.109-.822-1.427-.3-.186-.65-.284-1.01-.284Zm0 3.957c-1.327 0-2.406-1.008-2.406-2.246s1.08-2.245 2.406-2.245c.474 0 .933.129 1.327.373.676.417 1.079 1.118 1.079 1.872 0 .747-.397 1.443-1.06 1.862a2.515 2.515 0 0 1-1.346.383ZM17.211 16.35h-1.275a.287.287 0 0 1-.263-.161.253.253 0 0 1 .054-.289l.992-.99a.456.456 0 0 0 .082-.133.304.304 0 0 0 .007-.064c0-.156-.137-.283-.304-.283-.167 0-.303.127-.303.283 0 .148-.128.267-.286.267-.158 0-.286-.119-.286-.267 0-.45.392-.817.875-.817s.876.366.876.817c0 .075-.011.15-.033.222a.965.965 0 0 1-.21.339l-.542.542h.616c.158 0 .286.119.286.266 0 .148-.128.268-.286.268Z"
      fill={color}
    />
  </svg>
);

export default Co2AreasIcon;
