import { Circuit, CircuitWithReading } from "../../../lib/api/types/Circuit";
import { formatDeviceName } from "../../../lib/utils/formatDeviceName";
import { CircuitReading } from "../../property-graph/GraphUtils";

export const formatCircuitPhaseTitle = (
  circuit: Circuit | CircuitWithReading | CircuitReading,
  includeDeviceName = false
) => {
  if (!circuit) return "";

  const phaseDescriptor = Boolean(circuit?.phase === null && circuit?.type === "TOTAL")
    ? " Total"
    : " Phase " + circuit?.phase;

  if (includeDeviceName && (circuit as CircuitWithReading)?.device) {
    return formatDeviceName((circuit as CircuitWithReading)?.type) + " -" + phaseDescriptor;
  }

  return phaseDescriptor;
};
